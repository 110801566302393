import * as React from "react"
import Home from '../components/pages/Home'
import { graphql } from "gatsby"

const IndexPage = ({ data: { strapiHome } }) => (
  <Home strapiHome={strapiHome} />
)

export default IndexPage

export const pageQuery = graphql`
  query pageHomeQuery {
        strapiHome {
            id
            pageBuilder
            MetaDescription
            HeroCarousel {
                HeroCarouselSlide {
                    Title
                    paragraph
                    ButtonLink {
                    Slug
                    }
                    ButtonText
                    HeroImage {
                        provider_metadata {
                            public_id
                        }
                    }
                }
            }
        }
    }
`
import * as React from 'react'

import Layout from '../layout'
import Seo from '../seo'
import PageBuilder from '../common/PageBuilder/PageBuilder'
import HeroCarousel from '../common/PageBuilder/HeroCarousel'
import { Helmet } from 'react-helmet'

const Home = ({ strapiHome }) => {
	return (
		<Layout>
			<Helmet>
				<title>Delta Insurance | Home</title>
				<meta
					name="description"
					content={strapiHome.MetaDescription ? strapiHome.MetaDescription : ''}
				/>
			</Helmet>
			<h1 class="visually-hidden">Delta Insurance Home</h1>
			{strapiHome.HeroCarousel && (
				<HeroCarousel data={strapiHome.HeroCarousel} />
			)}

			{strapiHome.pageBuilder && <PageBuilder data={strapiHome.pageBuilder} />}
		</Layout>
	)
}

export default Home
